.cookieBox {
  background: rgba(52, 64, 81, 1) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 9px 67px !important;
}

.cookieButton {
  margin: auto !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  padding: 10px !important;
}
