table.tab-info.ui.table {
  border: 0px;
}
table.tab-info.ui.table td.data-title {
  font-style: italic;
  padding-left: 8px;
  background: rgba(34, 36, 38, 0.05);
}

table.tab-info.ui.table td.data-value {
  font-size: 1.1em;
}
.left-column {
  flex: 50%;
  padding-right: 15px;
}
.right-column {
  flex: 50%;
  padding-left: 15px;
}
.row-separator {
  display: flex;
  padding-top: 15px;
}
.center-column {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.chart {
  padding-top: 15px;
  padding-bottom: 15px;
}
