.search-entity-name-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.search-entity-name-link {
  /* font-weight: 600; */
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1rem;
}
