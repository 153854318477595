.news-link-to-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-link-to-content:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.news-issuer-name-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.news-issuer-name-link {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
