.CalendarDay__selected_span {
  background: #cdcdcd;
  border: 1px solid #fff;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #f5f5f5;
  border: 1px solid #fff;
  color: #9b9b9b;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #f5f5f5;
  border: 1px solid #fff;
  color: #9b9b9b;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #9b9b9b;
  border: 1px solid #fff;
  color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #5c5c5c;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #a5a5a5;
  bottom: 0;
  right: 0;
}

/* withTableDatePicker */
.DateInput {
  padding: 0px !important;
}

.DateRangePickerInput {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px !important;
}
