.box {
  display: flex;
  flex-flow: row nowrap;
}
.box > .child-filter {
  flex-grow: 1;
}

.box > .child-label {
  min-width: 117px;
}

.relation-result > em {
  color: rebeccapurple;
  font-weight: 600;
}
