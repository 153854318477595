tbody tr.coupons.visible.transition {
  display: table-row !important;
  color: grey;
}

.notoria.profit {
  color: #3f8e3b !important;
}

.notoria.loss {
  color: #f11e1e !important;
}

.notoria.nochange {
  color: #888 !important;
}

.notoria-tabs a {
  color: #00709a !important;
}

.notoria-tabs a:hover {
  color: #005580 !important;
}

i.notoria.chevron {
  font-size: 32px !important;
  height: 2em !important;
}

.notoria.price {
  color: #222222 !important;
  font-size: 18px !important;
  white-space: nowrap !important;
}

.notoria .caret {
  border-top: none !important;
  margin-bottom: 11px !important;
}

div.notoria.snapshot .ui.header {
  display: inline-block;
}

@media (min-width: 768px) {
  div.notoria.snapshot .ui.huge.header {
    font-size: 37px !important;
  }
}

div.notoria.snapshot .ui.huge.header {
  color: #222 !important;
  text-transform: none !important;
  font-weight: normal !important;
}

div.notoria.snapshot .ui.header.notoria.ccode {
  color: #888 !important;
  font-size: 22px !important;
  font-weight: normal !important;
}

div.notoria.snapshot .ui.header.notoria.date {
  color: #222 !important;
  font-size: 22px !important;
  font-weight: normal !important;
}

div.notoria.snapshot .ui.small.header {
  color: #888 !important;
  font-weight: normal !important;
  font-size: 16px !important;
}

div.notoria.snapshot .ui.small.header.notoria.price52w {
  color: #888 !important;
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

div.notoria.snapshot .price52w {
  color: #222 !important;
  font-size: 16px !important;
}

div.notoria.snapshot.grid h1,
div.notoria.snapshot.grid h2 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

div.notoria.accordion {
  padding: 5px !important;
}

@media (max-width: 768px) {
  .visibility-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .visibility-computer {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .text-align-mobile {
    text-align: left;
  }
}

.notoria.current .value.bold {
  color: #222222 !important;
  font-size: 18px !important;
}

.notoria.current {
  font-size: 16px !important;
}

/* withTableDatePicker */

.ui.table tr.notoria-date-input-picker {
  border: 0 !important;
  box-shadow: none !important;
}

/* QuotesChart */

@media (min-width: 768px) {
  div.nts-date-picker {
    margin-left: 10% !important;
  }
}

/* NewsList */

a.news-title-link {
  text-decoration: none;
  color: #222222;
  font-size: 1.2rem;
}

a.news-title-link:hover {
  color: #5c5c5c;
  text-decoration: none;
}
