#root,
#root > div {
  height: 100%;
}

.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -72px;
}
.page-wrap:after {
  content: '';
  display: block;
}

.site-footer,
.page-wrap:after {
  height: 72px;
}
