.row-header-rotate {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  writing-mode: tb-rl;
}

.ui.table.issues thead th {
  background: #2224260d;
}

.corporate-action-box,
.aid-box,
.documents-box {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #22242657;
}

.corporate-action-row,
.aid-row,
.documents-row {
  display: flex;
}

.corporate-action-row:first-child,
.aid-row:first-child,
.documents-row:first-child {
  border-bottom: none;
}

.corporate-action-item,
.aid-item,
.documents-item {
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.corporate-action-item:first-child,
.aid-item:first-child,
.documents-item:first-child {
  padding-left: 0;
  padding-right: 0;
}

.corporate-action-item:last-child,
.aid-item:last-child,
.documents-item:first-child {
  padding-left: 0;
  padding-right: 0;
}

.aid-footer {
  display: flex;
  justify-content: flex-end;
}
